import Joi from "joi";

export interface UserCustomer {
  userId: string;
  customerName?: string | null;
  cirrusCustomerId?: string | null;
  cirrusUserId?: string | null;
  scopes?: string[] | null;
}

const userCustomerSchema = Joi.object<UserCustomer>({
  userId: Joi.string().required(),
  customerName: Joi.string(),
  cirrusCustomerId: Joi.string(),
  cirrusUserId: Joi.string(),
  scopes: Joi.array().items(Joi.string()),
});
const userCustomerListSchema: Joi.AnySchema<UserCustomer[]> =
  Joi.array().items(userCustomerSchema);

export function isUserCustomer(obj: any): obj is UserCustomer {
  const { error } = userCustomerSchema.validate(obj);
  return !error;
}

export function setUserCustomers(list: UserCustomer[]) {
  window.sessionStorage.setItem("userCustomers", JSON.stringify(list));
}

export function getUserCustomers() {
  try {
    const str = sessionStorage.getItem("userCustomers") || "[]";
    const data = JSON.parse(str);
    const { error, value } = userCustomerListSchema.validate(data);
    if (error) {
      console.error("sessionStorage contains invalid userCustomers", error);
    }
    return value;
  } catch (err) {
    console.error("Unable to parse user customers", err);
  }
}
