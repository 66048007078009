import axios from "axios";
import Cookies from "js-cookie";
import { CookieAttributes } from "js-cookie";
import { setUserCustomers, isUserCustomer } from "./userCustomer";

const authorize = async (state?: string) => {
  const loginToken = Cookies.get("loginToken");
  const authUrl = `/oauth/authorize${state || location.search}`;

  if (loginToken) {
    window.location.href = authUrl;
  }
};

const loginTokenOptions = (): CookieAttributes => ({
  sameSite: "None",
  secure: true,
});

const getErrorResponse = (o: unknown): Partial<Record<string, unknown>> => {
  if (o && typeof o === "object") return o;
  else return {};
};

const login = async (email: string, password: string) => {
  let res;
  try {
    res = await axios.post("/authenticate/login", { email, password });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const { error, error_description } = getErrorResponse(e.response?.data);
      if (
        error == "invalid_grant" &&
        error_description ==
          "We can't find an account with that email adress, please try again"
      ) {
        return "Invalid email";
      } else if (
        error == "invalid_grant" &&
        error_description == "Wrong password, please try again."
      ) {
        return "Invalid password";
      }
    }
    throw e;
  }

  if (res.status === 200) {
    return await handleLoginResult(res.data);
  }
};

const handleLoginResult = async (result: Record<string, unknown>) => {
  const {
    requiresTwoFactorVerification,
    twoFactorSetupRequired,
    loginToken,
    userCustomers,
    state,
  } = result;

  if (typeof loginToken !== "string")
    throw new Error("loginToken is missing from login response");

  Cookies.set("loginToken", loginToken, loginTokenOptions());

  if (requiresTwoFactorVerification) {
    const twoStepVerificationUrl = `/account/verify${state || location.search}`;
    return location.replace(twoStepVerificationUrl);
  }

  if (twoFactorSetupRequired) {
    const twoStepSetupUrl = `/account/2fasetup${state || location.search}`;
    return location.replace(twoStepSetupUrl);
  }

  if (userCustomers) {
    if (Array.isArray(userCustomers) && userCustomers.length > 1) {
      const chooseCustomersUrl = `/account/choose-customer${
        state || location.search
      }`;
      const uc = userCustomers.filter(isUserCustomer);
      if (uc.length != userCustomers.length)
        console.warn("The server returned partial/invalid UserCustomer data");
      setUserCustomers(uc);
      return location.replace(chooseCustomersUrl);
    }

    await authorize(typeof state == "string" ? state : undefined);
  }
};

const verifyTwoFactor = async (verificationCode: string) => {
  let res;
  try {
    res = await axios.post("/authenticate/verify-two-factor", {
      verificationCode,
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const { error, error_description } = getErrorResponse(e.response?.data);
      if (error == "invalid_request") {
        if (error_description == "Failed at verification step") {
          return "invalid";
        } else if (error_description == "Two factor secret not set up") {
          return "2faSecretMissing";
        }
      }
    }
    throw e;
  }

  if (res.status === 200) {
    const { loginToken } = res.data;

    Cookies.set("loginToken", loginToken, loginTokenOptions());
    return true;
  } else {
    return false;
  }
};

const twoFactorStatus = async () => {
  const res = await axios.get("/authenticate/two-factor-status");

  if (res.status === 200) {
    const { customerRequiresTwoFactor, usesTwoFactor } = res.data;

    if (
      typeof customerRequiresTwoFactor === "boolean" &&
      typeof usesTwoFactor === "boolean"
    ) {
      return { customerRequiresTwoFactor, usesTwoFactor };
    }
  }
};

const setupTwoFactor = async () => {
  const res = await axios.post("/authenticate/setup-two-factor");

  if (res.status === 200) {
    const { secret, otpUrl } = res.data;

    if (typeof secret === "string" && typeof otpUrl === "string") {
      return { secret, otpUrl };
    }
  }
};

const enableTwoFactor = async (verificationCode: string) => {
  let res;
  try {
    res = await axios.post("/authenticate/enable-two-factor", {
      verificationCode,
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const { error, error_description } = getErrorResponse(e.response?.data);
      if (error == "invalid_request") {
        if (error_description == "Failed verification") {
          return "invalid";
        }
      }
    }
    throw e;
  }

  if (res.status === 200) {
    const { loginToken } = res.data;

    if (loginToken) {
      Cookies.set("loginToken", loginToken, loginTokenOptions());
      return true;
    }
  }
  return false;
};

const disableTwoFactor = async () => {
  let res;
  try {
    res = await axios.post("/authenticate/disable-two-factor");
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const { error, error_description } = getErrorResponse(e.response?.data);
      if (
        error == "unauthorized_user" &&
        error_description === "Customer requires 2FA"
      ) {
        return "customerRequires2FA";
      }
    }
    throw e;
  }

  if (res.status === 200) {
    const { loginToken } = res.data;
    if (loginToken) {
      Cookies.set("loginToken", loginToken, loginTokenOptions());
      return true;
    }
  }
  return false;
};

const checkAccessToken = async () => {
  const accessToken = Cookies.get("access_token");
  if (accessToken) {
    await authorize();
  }
};

const checkLogin = () => {
  const loginToken = Cookies.get("loginToken");
  if (!loginToken) {
    const loginUrl = `/account/login${location.search}`;
    location.replace(loginUrl);
  }
};

const cancelAuthorization = () => {
  window && window.history.back();
};

const confirmAuthorization = async () => {
  checkLogin();
  await authorize();
};

const requestPasswordReset = async (email: string, state: string) => {
  try {
    await axios.post("/authenticate/request-password-reset", {
      email,
      state,
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const { error, error_description } = getErrorResponse(e.response?.data);
      if (
        error == "invalid_grant" &&
        error_description ==
          "We can't find an account with that email adress, please try again"
      ) {
        return "missingEmail";
      }
    }
    throw e;
  }
};

export function removePrompt(search: string, prompt: string): string {
  const params = new URLSearchParams(search);
  const newPrompt = params
    .get("prompt")
    ?.split(" ")
    .filter((x) => x !== prompt)
    .join(" ");
  if (newPrompt && typeof newPrompt === "string") {
    params.set("prompt", newPrompt);
  } else {
    params.delete("prompt");
  }
  if (params.size) return '?' + params.toString();
  else return "";
}

const resetPassword = async (resetToken: string, password: string) => {
  let res;
  try {
    res = await axios.post("/authenticate/reset-password", {
      resetToken,
      password,
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const { error, error_description } = getErrorResponse(e.response?.data);
      if (
        error == "invalid_grant" &&
        error_description == "Invalid resetToken"
      ) {
        return "Invalid resetToken";
      }
    }
    throw e;
  }

  if (res.status === 200) {
    const { requiresTwoFactorVerification, loginToken, userId, state } =
      res.data;
    if (state) {
      const newUrl = location.origin + location.pathname + state;
      window.history.replaceState(history.state, document.title, newUrl);
    }

    if (requiresTwoFactorVerification) {
      const twoStepVerificationUrl = `/account/verify${location.search}`;
      Cookies.set("userId", userId);
      return location.replace(twoStepVerificationUrl);
    }

    Cookies.set("loginToken", loginToken, loginTokenOptions());
    if (location.search.includes("client_id")) await authorize();
    else location.href = "https://cirruscrm.io";
    return "ok";
  }

  return "error";
};

const chooseCustomer = async (userId: string) => {
  const loginToken = Cookies.get("loginToken");
  if (!loginToken) return false;
  const chooseCustomerUrl = `/authenticate/choose-customer${location.search}`;

  const res = await axios.post(
    chooseCustomerUrl,
    { userId },
    { headers: { loginToken } },
  );

  if (res.status === 200) {
    Cookies.set("loginToken", res.data.loginToken, loginTokenOptions());
    await authorize();
    return "ok";
  }

  return res;
};

export {
  authorize,
  checkAccessToken,
  login,
  checkLogin,
  cancelAuthorization,
  confirmAuthorization,
  verifyTwoFactor,
  requestPasswordReset,
  resetPassword,
  chooseCustomer,
  setupTwoFactor,
  enableTwoFactor,
  twoFactorStatus,
  disableTwoFactor,
  handleLoginResult,
};
